<template>
  <div>
    <air-error-500 />
  </div>
</template>
<script>
import AirError500 from '@/components/system/Errors/500'
export default {
  components: {
    AirError500,
  },
}
</script>
